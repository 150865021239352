<template>
	<div data-component="study-deal-item">
		<div data-element="base-row">
			<icon
				v-if="getIsCompleted"
				icon="check"
				colour="green"
				data-element="completed-deal-icon"
			/>
			<span
				v-else
				data-element="not-completed-deal-icon"
			/>
			<p data-element="name">
				Deal {{ index + 1 }}
			</p>
			<div data-element="actions">
				<V2Button
					v-if="getPlayIsVisible"
					size="xs"
					fit
					:btnId="deal.id"
					@click="handlePlayDeal"
				>
					<icon class="play-icon" icon="v2-deal-play" colour="transparent" />
				</V2Button>
			</div>
		</div>
		<div
			v-if="getIsShowAdditionalActions"
			data-element="completed-row"
		>
			<V2Button
				v-if="getDealAnalysis"
				size="xs"
				fit
				:url="getDealAnalysis.url"
			>
				Show deal analysis
			</V2Button>
			<V2Button
				v-if="getDealRecording"
				size="xs"
				fit
				@click="handleShowDealRecording"
			>
				Show deal run-through
			</V2Button>
		</div>
	</div>
</template>

<script>

	import Icon from '@/components/ui/Icon';
	import V2Button           from '@/components/v2/ui/V2Button';
	import routeParams from '@/mixins/routeParams';
	import { dealAnalysisType, dealRecordingType } from '@/consts';

	export default {
		components: {
			Icon,
			V2Button
		},
		mixins: [routeParams],
		props: {
			deal: {
				type: Object,
				default: undefined
			},
			index: {
				type: Number,
				required: true
			},
			isDisabled: {
				type: Boolean,
				default: false
			}
		},
		data: () => ({}),
		computed: {
			getIsTeacherView () {
				return this.getContext === 'teaching';
			},
			getIsStudentView () {
				return this.getContext === 'myCourses';
			},
			getIsCompleted () {
				return this.deal?.isComplete;
			},
			getPlayIsVisible () {
				if (this.getIsTeacherView) {
					return true;
				}
				return !this.isDisabled;
			},
			getDealMedia () {
				return this.deal?.media || [];
			},
			getDealAnalysis () {
				return this.getDealMedia.find(item => item.config?.type === dealAnalysisType);
			},
			getDealRecording () {
				return this.getDealMedia.find(item => item.config?.type === dealRecordingType);
			},
			getIsShowAdditionalActions () {
				const rules = [!!(this.getDealAnalysis || this.getDealRecording)];
				if (this.getIsStudentView) {
					rules.push(this.getIsCompleted);
				}

				return rules.every(r => r);
			}
		},
		methods: {
			handlePlayDeal () {
				this.$emit('onPlayDeal');
			},
			handleShowDealRecording () {
				if (this.getDealRecording) {
					this.$emit('onShowDealRecording', this.getDealRecording.url);
				}
			}
		}
	};

</script>

<style lang="scss" scoped>

  .play-icon {
    width: 16px;
    height: 16px;
  }

	[data-component='study-deal-item'] {
		display: flex;
		flex-direction: column;
		padding: rem(10) rem(14) rem(10) rem(12);
		background-color: $c-brand-grey-lighter-8;
		border-radius: 4px;

		&:not(:last-child) {
			margin-bottom: rem(10);
		}

		[data-element='base-row'] {
			display: flex;
			align-items: center;

			[data-element='not-completed-deal-icon'] {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				flex-shrink: 0;
				width: rem(28);
				height: rem(28);
				margin-right: rem(8);

				&:after {
					content: '';
					display: inline-block;
					background-color: $c-brand-grey-lighter-4;
					border-radius: 50%;
					width: rem(18);
					height: rem(18);
				}
			}

			[data-element='completed-deal-icon'] {
				flex-shrink: 0;
				width: rem(28);
				height: auto;
				margin-right: rem(8);
			}

			[data-element='name'] {
				overflow: hidden;
				flex-shrink: 1;

        @include font;

				margin-right: rem(24);
				white-space: nowrap;
				text-overflow: ellipsis;
			}

			[data-element='actions'] {
				margin-left: auto;
			}
		}

		[data-element='completed-row'] {
			display: flex;
			align-items: center;
			margin-top: rem(8);

			[data-component='btn'] {
				&:not(:last-child) {
					margin-right: rem(8);
				}
			}
		}

	}

</style>
